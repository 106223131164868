import * as _ from 'lodash';

const textContent = {
    mainTitle: 'Valitse henkilökohtainen etusi',
    ingress: 'Olemme valinneet sinulle edun, jonka voit lunastaa Plussa-kortillasi. Halutessasi voit vaihtaa tuotteen toiseen tai valita kassalla annettavan euromääräisen alennuksen.',
    disclaimer: 'Olet mukana palkitsemista koskevassa kokeilussa, jossa saat maalis-huhtikuussa viikoittain vaihtuvan henkilökohtaisen tuotteen veloituksetta. Etu on ladattuna valmiiksi Plussa-kortillesi, ja se on voimassa vain edussa mainitussa K-ruokakaupassa.',
    discountDisclaimer: 'Euromääräinen alennus on käytettävissä ostosten ylittäessä alennuksen suuruuden. Ostorajan täyttymisessä ei huomioida lahjakortteja, pullonpantteja, äidinmaidonkorvikkeita, tupakka-, alkoholi- tai nikotiinikorvaustuotteita eikä Postin tai Veikkauksen tuotteita tai muita välitystuotteita. Ei muita alennuksia.',
    tinyDisclaimer: '© Kesko 2019 Verkkopalvelussamme käytetään evästeitä käyttäjäkokemuksen parantamiseen. Käyttämällä palvelua hyväksyt evästeiden käytön. Katso palvelun käyttöehdot, sopimusehdot, K-Ruoka ja verkkokauppa -asiakasrekisteri, K-ryhmän online-tunnistekäytännöt, K-tunnuksen tietosuojaseloste ja Oiva-raportit.',
    free: 'Veloituksetta',
    days: 'Päivää',
    select: 'Vaihda tähän',
    selected: 'Valittu',
    unavailable: 'Ei valittavissa',
    unavailable_used: 'Etu käytetty',
    validUntil: 'Voimassa vielä',
    remaining: 'Jäljellä',
    activating: 'Aktivoidaan etua...',
    modal_success_title: 'Etu on nyt vaihdettu!',
    modal_success_text: 'Voit lunastaa edun Plussa-kortillasi',
    modal_error_title: 'Jotain meni pieleen',
    modal_error_text_activation: 'Edun vaihto epäonnistui',
    ribbon: 'Etu käytetty',
    genericErrorTitle: 'Jotain meni pieleen!',
    genericErrorText: 'Yritä joskus uudelleen',
};

export function getText(key) {
    return _.get(textContent, key, '????');
};
