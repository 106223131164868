import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql} from "gatsby"

import Header from "./header"
import "../styles/layout.scss"
import "../styles/font.scss"

import plussaLogo from "../images/k-plussa-logo.png";

import {getText} from "../utils/texts";

const Layout = ({children}) => (
    <>
        <div className="content">
            <h1>{getText('mainTitle')}</h1>
            <div>
                {children}
            </div>
            <div className="disclaimer">{getText('disclaimer')}</div>
            <div className="plussa-logo">
                <img src={plussaLogo} alt=""/>
            </div>
            <div className="footer">
                {getText('discountDisclaimer')}<br/><br/>
                {getText('tinyDisclaimer')}
            </div>
        </div>
    </>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
