import * as _ from 'lodash';
import * as qs  from 'qs';
import { differenceInDays } from 'date-fns';
import {getText} from "./texts";

async function makeRequest(url, method) {
    try {
        const response = await fetch(url, {
            method: method,
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });
        const success = response.status === 200;
        const payload = await response.json();
        return { success, payload }
    } catch(e) {
        console.log('Error in fetch GET', e);
        return { success: false, payload: {}};
    }
}

const CAMPAIGN_URL = '/api/targetedads/';
const getActivationURL = (number) => `/api/activate/${number}/activate-1-of-n`;

function getPersonID() {
    const queryString = document.location.search.split('?')[1];
    const query = qs.parse(queryString);
    return query['p'];
}

function getChainImage(chain) {
    return `https://public.keskofiles.com/f/logos/chains/${chain}_dark?w=140`;
}

function getStoreName(marketingOrganizationName) {
    if( _.isString(marketingOrganizationName)) {
        const parts = marketingOrganizationName.split(' ');
        parts.splice(0, 1);
        if (!_.isNil(parts)) {
            const storeName = parts.join('');
            return storeName;
        }
    }
    return "";
}

export async function getCampaigns() {
    const personID = getPersonID();
    const scamId = `?iescam=${Math.random()}`; // For busting IE cache
    const response = await makeRequest(`${CAMPAIGN_URL}${personID}${scamId}`, 'GET');
    if (response.success === true) {
        const offers = response.payload;
        // Filter out all offers that dont have Valintaryhma attribute
        const filteredOffers = offers.filter(c => {
            const offerAttributes = _.get(c, ['offer', 'attributes'], []);
            const isSelectionGroup = !_.isNil(_.find(offerAttributes, attr => attr.key === 'Valintaryhma' && attr.value === "1"));
            return isSelectionGroup;
        });

        // Group the campaigns by number (just in case someone decides to make two of these)
        const offerGroups = _.groupBy(filteredOffers, 'campaignId');

        // Mark campaigns with an entry with status 1 as used
        const usedOffers = Object.keys(offerGroups).map(groupKey => {
            const group = offerGroups[groupKey];
            const usedProductExists = _.find(group, product => product.status === 1) !== undefined;
            return usedProductExists ? groupKey : undefined;
        }).filter(r => r);


        const unusedOfferAvailable = Object.keys(offerGroups).length > usedOffers.length;

        const campaignStatus = unusedOfferAvailable ? 'AVAILABLE': 'USED';
        const displayedOffer = offerGroups[Object.keys(offerGroups)[0]] || [];
        const now = new Date();

        // Mutilate the data model for better fit on the UI side
        const productData = displayedOffer.map(product => {
            const offer = product.offer;

            const priceNumber = parseFloat(offer.price);
            const priceText = _.isFinite(priceNumber) && priceNumber !== 0 ? `${priceNumber.toFixed(2)} €`: getText('free');

            return {
                selected: product.status === 0 || product.status === 1,
                number: product.number,
                image: offer.imageUrl,
                brand: offer.header1,
                name: offer.header2,
                price: priceText,
                normalPrice: offer.normalPrice,
                validUntil: differenceInDays(product.validUntil, now),
                remaining: product.remainingQty,
                remainingUnit: offer.priceUnit,
                chainImage: getChainImage(product.marketingOrganizationChains[0]),
                storeName: getStoreName(product.marketingOrganizationName),
            };
        });
        response.payload = {
            campaignStatus,
            products: _.sortBy(productData, 'number') // No idea what should be the sort key but the order changes on API response
        };
    } else {
        response.payload = {
            products: [],
        }
    }
    return response;
}

export async function activate(offerNumber) {
    const response = await makeRequest(getActivationURL(offerNumber), 'PUT');
    return response;
}
