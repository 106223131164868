import React from "react";
import PropTypes from "prop-types";
import "../styles/layout.scss";
import "../styles/font.scss";

import closeIcon from '../images/icon-tick.svg';

const Modal = ({title, text, icon, closeFn}) => (
    <div className="modal">
        <div className="modal-content">
            <div className="close-container" onClick={closeFn}>
                <img src={closeIcon} alt=""/>
            </div>
            <img src={icon} alt="" className="modal-icon"/>
            <div className="modal-title">{title}</div>
            <div className="modal-text">{text}</div>
        </div>
    </div>
);

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    closeFn: PropTypes.func.isRequired,
};

export default Modal;
