import React from "react";

import Layout from "../components/layout"
import {activate, getCampaigns} from "../utils/api";
import {getText} from "../utils/texts";
import spinner from '../images/icons-spinner.svg';
import checkIcon from '../images/icon-check.png';
import errorIcon from '../images/images-sadtoast.svg';

import Modal from "../components/modal";
import * as _ from "lodash";

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            successModalOpen: false,
            errorModalOpen: false,
            errorText: '',
            campaignUsed: false,
        };
        this.closeSuccessModal = this.closeSuccessModal.bind(this);
        this.closeErrorModal = this.closeErrorModal.bind(this);
    }

    async refreshCampaigns() {
        const campaignResponse = await (getCampaigns());
        if (campaignResponse.success === true) {
            const campaignUsed = campaignResponse.payload.campaignStatus === 'USED'; // otherwise available
            const products = campaignResponse.payload.products;
            this.setState(() => ({
                products,
                campaignUsed,
            }));
        } else {
            this.setState(() => ({
                products: []
            }));
        }
    }

    async componentDidMount() {
        this.refreshCampaigns();
    }

    async selectProduct(number) {
        this.setState((state) => {
            const products = state.products;
            const product = products.find(p => p.number === number);
            product.loading = true;
            return {products};
        });
        const result = await activate(number);
        if (result.success) {

            const errorCode = _.get(result, ['payload', 'code']);
            if (_.isNumber(errorCode)) {
                this.setState(() => ({
                    errorText: getText('modal_error_text_activation'),
                    errorModalOpen: true,
                }));
            } else {
                this.setState(() => ({successModalOpen: true}));
            }
        } else {
            this.setState(() => ({errorModalOpen: true}));
        }
        this.refreshCampaigns();
    }

    closeSuccessModal() {
        this.setState(() => ({successModalOpen: false}));
    }

    closeErrorModal() {
        this.setState(() => ({errorModalOpen: false}));
    }

    getButtonText(disabled, selected) {
        if (disabled) {
            return selected ? getText('unavailable_used') : getText('unavailable');
        }
        return selected ? getText('selected') : getText('select');
    }

    render() {
        return (<Layout>
            <h2>{getText('ingress')}</h2>
            { !_.isNil(this.state.products) && this.state.products.length === 0 && (
                <div className="error">
                    <img src={errorIcon} alt=""/>
                    <div className="error-title">{getText('genericErrorTitle')}</div>
                    <div className="error-text">{getText('genericErrorText')}</div>
                </div>
            )}
            <div className="product-list">
                {!_.isNil(this.state.products) && this.state.products.map(product => (
                        <div className="product-card" key={product.number}>
                            <div
                                className={`card-content ${product.selected && !this.state.campaignUsed ? 'selected' : ''}`}>
                                {product.selected && this.state.campaignUsed && (
                                    <div className="ribbon-container">
                                        <div className="ribbon">
                                            <span>{getText('ribbon')}</span>
                                        </div>
                                    </div>
                                )}
                                {product.loading && (
                                    <div className="loading">
                                        <div className="content">
                                            <div className="text">
                                                {getText('activating')}
                                            </div>
                                            <div className="spinner-container">
                                                <img src={spinner} alt="loading-spinner"/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="store-info">
                                    <img src={product.chainImage} alt="Chain logo"
                                         className="chain"/>
                                    <div className="store-name">
                                        {product.storeName}
                                    </div>
                                </div>
                                <div className="product-image">
                                    <img src={product.image} alt=""/>
                                </div>
                                <div className="product-brand">{product.brand}</div>
                                <div className="product-name">{product.name}</div>
                                <div className="product-price">{product.price}</div>
                                <div className="offer-details">
                                    <div className="detail-col left">
                                        <div className="title">{getText('validUntil')}</div>
                                        <div className="value">{product.validUntil}&nbsp;{getText('days')}</div>
                                    </div>
                                    <div className="splitter"></div>
                                    <div className="detail-col right">
                                        <div className="title">{getText('remaining')}</div>
                                        <div className="value">{product.remaining}&nbsp;{product.remainingUnit}</div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <button
                                        className={`${product.selected ? 'selected' : ''} ${this.state.campaignUsed ? 'disabled' : ''}`}
                                        onClick={this.state.campaignUsed ? undefined : this.selectProduct.bind(this, product.number)}>
                                        {this.getButtonText(this.state.campaignUsed, product.selected)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            {this.state.successModalOpen && (
                <Modal icon={checkIcon}
                       title={getText('modal_success_title')}
                       text={getText('modal_success_text')}
                       closeFn={this.closeSuccessModal}
                ></Modal>
            )}
            {this.state.errorModalOpen && (
                <Modal icon={errorIcon}
                       title={getText('modal_error_title')}
                       text={this.state.errorText}
                       closeFn={this.closeErrorModal}
                ></Modal>
            )}
        </Layout>)
    }

}

export default IndexPage;
